.u-table-striped {
    @extend .table;
    @extend .table-striped;
}

.u-table-bordered {
    @extend .table;
    @extend .table-bordered;
}

.u-overflow-hidden {
	overflow: hidden;
}

.u-background-grey {
	background-color: #ebebeb;
}

.u-flex {
	@include respond-over($screen-sm) {
		display: flex;
	}
}