/*

---

Don't update this whole file with what is downloaded from flaticon!

Please just update the bit marked out below.

---

*/

@font-face {
    font-family: "Flaticon";
    src: url("./icons/Flaticon.eot");
    src: url("./icons/Flaticon.eot?#iefix") format("embedded-opentype"),
        url("./icons/Flaticon.woff") format("woff"),
        url("./icons/Flaticon.ttf") format("truetype"),
        url("./icons/Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: "Flaticon";
        src: url("./icons/Flaticon.svg#Flaticon") format("svg");
    }
}

.fi:before,
.fi:after, {
    display: inline-block;
    font-family: "Flaticon";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

/* Update from here */

.flaticon-close:before { content: "\f100"; }
.flaticon-down-arrow:before { content: "\f101"; }
.flaticon-facebook-logo-button:before { content: "\f102"; }
.flaticon-keyboard-right-arrow-button:before { content: "\f103"; }
.flaticon-linkedin-button:before { content: "\f104"; }
.flaticon-menu-circular-button:before { content: "\f105"; }
.flaticon-right-arrow:before { content: "\f106"; }
.flaticon-search:before { content: "\f107"; }
.flaticon-twitter-logo-button:before { content: "\f108"; }

$font-Flaticon-close: "\f100";
$font-Flaticon-down-arrow: "\f101";
$font-Flaticon-facebook-logo-button: "\f102";
$font-Flaticon-keyboard-right-arrow-button: "\f103";
$font-Flaticon-linkedin-button: "\f104";
$font-Flaticon-menu-circular-button: "\f105";
$font-Flaticon-right-arrow: "\f106";
$font-Flaticon-search: "\f107";
$font-Flaticon-twitter-logo-button: "\f108";