.vacancy {
	position: relative;
	padding-right: 40px;

	@include respond-over($screen-xs) {
		min-height: 400px;
	}

	&-details {
		margin-bottom: 15px;
	}

	&-item {
		font-size: 18px;
		color: #747577;
		margin-bottom: 5px;
		font-weight: bold;
	}

	&-link {
		position: absolute;
		bottom: 0;
	}

}

.vacancy-detail {
	padding-top: 50px;
	font-size: 18px;
	color: #747577;

	&-date {
		font-size: 16px;
	}

	&-title {
		font-size: 35px;
		color: #381d54;
		margin-top: 0;
		margin-bottom: 5px;
		display: inline-block;
	}

	&-list {
		padding-left: 0;
		margin-bottom: 20px;
	}

	&-item {
		display: inline-block;
	}

	&-place {
		margin-left: 10px;
	}

	&-description {
		p {
			font-weight: normal;
		}
		li {
			font-weight: normal;
		}
	}

}

.vacancies, .testimonials, .candidates {

	&-block {
		padding: 80px 0;

		&:first-child {
			padding-top: 60px;
		}
	}

	&-heading {
		font-size: 24px;
		color: #381d54;
		margin-bottom: 10px;
		padding-top: 40px;
		margin-top: 0;
	}

	&-filters {
		padding-top: 40px;

		label {
			padding-right: 20px;
		}

	}

	&-load-more {
		text-align: center;
		margin-top: 50px;
		margin-bottom: 93px;

		.load-more {
			background: none;
		    border: 2px solid #555759;
		    color: #555759;
		    border-radius: 60px;
		    font-size: 30px;
		    width: 50px;
		    margin: 0 auto;

			&-text {
				position: absolute;
				display: block;
				margin-left: -85px;
				margin-top: 20px;
				font-size: 24px;
				color: #381d54;
			}

		}

		.candidates-load-more-text {
			margin-left: -100px;
		}

	}

}


.candidates {

	&-no-of-results {
		padding-top: 40px;
	}

}
