.contact {

	padding: 80px 0;

	h3 {
		font-size: 20px;
		word-break: break-all;

		@include respond-over($screen-xs) {
			font-size: 24px;
		}

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 50px;

			@include respond-over($screen-xs) {
				margin-bottom: 0;
			}

		}
	}

	&-block {
		padding-left: 20px;
		border-left: 2px solid #381d54;
		margin-bottom: 40px;

		&:last-child {
			margin-bottom: 0;
		}

	}

	&-heading {
		font-size: 35px;
		color: #381d54;
		margin-top: 0;
	}

	&-text {
		font-size: 18px;
		color: #747577;
	}

	button {
		background: transparent;
		color: #381d54;
		border: 0;
		font-size: 24px;
	}

	form {
		margin-bottom: 50px;

		@include respond-over($screen-xs) {
			margin-bottom: 0px;
		}
	}

}

#map {
	width: 100%;
	height: 400px;
	background-color: grey;
}