.footer {

	&-logo {
		margin-top: 30px;
	}

	&-bottom {
		background-color: #ffffff;
		padding: 40px 0;
		color: #343434;
		font-size: 12px;
		text-align: center;

		@include respond-over($screen-xs) {
			font-size: 14px;
		}

		@include respond-over($screen-md) {
			background-color: #2e2e2e;
			color: #787878;
			text-align: left;
		}

		&-text {
			margin: 0;
			font-size: 14px;
		}

		a {
			color: #343434;

			@include respond-over($screen-xs) {
				color: #787878;
			}
		}

		.pipe-symbol {
			padding: 0 5px;

			@include respond-over($screen-xs) {
				padding: 0 10px;
			}

		}

	}

}

