.content-blocks {
	padding: 60px 0 15px;
}

.content-block {
	position: relative;
	border-left: 2px solid #b1aeb0;
	padding-left: 20px;
	margin-bottom: 50px;
	height: 100%;

	@include respond-over($screen-xs) {
		margin-bottom: 0;
	}

	&-heading {
		font-size: 35px;
		color: #381d54;
		margin-bottom: 40px;
		margin-top: 0;
	}

	&-text {

		margin-bottom: 60px;

		p {
			font-size: 18px;
		}

	}

	&-link {
		position: absolute;
		bottom: 0;
		font-size: 20px;
		color: #381d54;
		margin-top: 40px;
		display: block;
	}

    .button {
        a:hover {
            text-decoration: underline;
        }
        &:after {
            content: none;
        }
    }

}

.staff-blocks {
	.col-md-4 {
		&:last-child {
			.staff-block {
				margin-bottom: 0;
			}
		}
	}
}

.staff-block {

	margin-bottom: 80px;

	@include respond-over($screen-md) {
		margin-bottom: 0;
	}

	&-image {
		margin-bottom: 10px;
		display: block;

		img {
			border-radius: 150px;
		}

	}

	&-name {
		margin-bottom: 20px;
	}

	&-text {
		font-weight: 100;
	}

}
