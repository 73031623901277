.blog {

	padding: 50px 0;

	ul {
		margin-left: 0;
		
		li {
			color: #747577;
			font-size: 18px;

		}
	}

	h1 img, h2 img, h3 img, h4 img, h5 img, h6 img, p img {
		text-align: left !important;
	}

	.fr-dib {
	    float: left;
	    margin: 0 15px 0px 0px;
	}

	&-posts {

		@include respond-over($screen-xs) {
			border-left: 1px solid #dcdcdc;
			padding-left: 40px;
		}

	}

	&-heading {
		font-size: 30px;
		color: #381d54;
		margin-top: 0;
		margin-bottom: 20px;

		@include respond-over($screen-xs) {
			font-size: 35px;
		}

		a {
			color: #381d54;
		}

	}

	&-search {

		width: 90%;
		position: relative;

		&-input {
			border: 0;
			border-bottom: 1px solid #dcdcdc;
			font-size: 24px;
			margin-bottom: 40px;
			width: 100%;
		}

		&-button {
			position: absolute;
			top: 0;
			right: 0;
			width: 40px;
			background: none;
			border: 0;
			color: #dcdcdc;
			font-size: 20px;
		}

	}

	&-categories {
		padding: 0;
		list-style: none;
	}

	&-category {
		font-size: 20px;
		padding-bottom: 10px;

		&:last-child {
			padding: 0;
		}

		a {
			color: #381d54;
		}

	}

	.mod-border-top {
		margin-top: 40px;
		padding-top: 30px;
		border-top: 1px solid #dcdcdc;
	}

	&-archive {
		padding-bottom: 20px;
		margin-bottom: 40px;
		border-bottom: 1px solid #dcdcdc;
	}

	&-image {
		width: 100%;
		height: 300px;
		background-size: cover;
		background-position: center center;
		margin-bottom: 40px;

		@include respond-over($screen-md) {
			width: 40%;
			margin-bottom: 0;
		}

	}

	&-left {
		float: left;

		@include respond-over($screen-md) {
			margin-right: 40px;
		}

	}

	&-right {
		float: right;

		@include respond-over($screen-md) {
			margin-left: 40px;
		}

	}

	&-post {
		padding-bottom: 40px;
		margin-bottom: 40px;
		border-bottom: 1px solid #dcdcdc;
	}

	&-link {
		color: #381d54;
		font-size: 20px;
	}

	&-post-categories {
		color: #381d54;
		font-weight: bold;
	}

	&-post-date {
		color: #646668;
		font-weight: bold;
	}

	.excerpt {
		color: #747577;
	}

	.pagination {
		padding-left: 10px;

		a {
			color: #747577;
		}

		.active {

			a {
				color: #381d54;
				font-weight: bold;
			}

		}

	}

}

.blog-posts {

	.alignright {
		float:right;
		margin:0px 0 15px 15px;
	}

	.alignleft {
	    margin: 0px 15px 15px 0;
	    float:left;
	}

	.aligncenter {
	    float: none;
	    margin: 15px auto;
	    display: block;
	}

	img[class*=wp-image-] {
	    max-width:100%;
	    height:auto;
	}

}


.fr-dii.fr-fir {
	display: inline;
	float: left;
	margin-right: 2rem;
}

.fr-fir.fr-dii {
	display: inline;
	float: right;
	margin-left: 2rem;
}