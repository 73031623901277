/*
** Folder structure loosely based on: http://www.sitepoint.com/architecture-sass-project/
** Preferred CSS Methodology: https://gist.github.com/bobbygrace/9e961e8982f42eb91b80
*/

// Core variables and mixins
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";

// Overwrite variables
@import "./extensions/bootstrap";

// Reset and dependencies
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/print";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";

// Core CSS
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/type";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/forms";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/code";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/buttons";

// Components
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navs";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navbar";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pagination";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pager";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/labels";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/badges";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/jumbotron";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/thumbnails";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/progress-bars";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/media";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/list-group";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/panels";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/wells";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/close";

@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/alerts";

// Components w/ JavaScript
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/modals";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tooltip";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/popovers";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/carousel";

// Utility classes
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";

// October CMS framework extras
@import "../../modules/system/assets/css/framework.extras";

// Helpers
@import "./helpers/functions";
@import "./helpers/variables";
@import "./helpers/mixins";

// Base
@import "./base/reset";
@import "./base/typography";
@import "./base/utilities";
@import "./base/flaticon";

// Layout
@import "./layout/footer";

// Pages
@import "./pages/contact";
@import "./pages/blog";
@import "./pages/candidates";

// Components
@import "./components/browser-upgrade";
@import "./components/buttons";
@import "./components/forms";
@import "./components/3-content-blocks";
@import "./components/ctas";
@import "./components/testimonials";

// Slick
@import "./plugins/slick";
@import "./plugins/slick-theme";
