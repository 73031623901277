/* =================================================================== */
/* == Page Layout ==================================================== */
/* =================================================================== */

html, body {
    position:relative;
    height:100%;
    width:100%;
}

/* =================================================================== */
/* == Generic Fixes ================================================== */
/* =================================================================== */

/* This element causes scrollbars. It isn't needed... so just hide it! */
#fb-root { display:none; }

/* Give a google maps wrapper this class to stop bootstrap breaking the styles */
.google-map {
    * { box-sizing: content-box; }
    label { width: auto; display:inline; }
}

/* Fix for HTML dividers covering edit links */
.html-divider {
    margin:0!important;
    float:left!important;
}
