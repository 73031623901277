input, textarea, select {
    &.is-erroneous {
        border: 1px solid #a94442;
        color: #a94442;
    }
}

.input-error-message {
    color: #a94442;
    text-align: right;
    padding-top: 5px;
}

.contact,
.vacancies-filters,
.testimonials-filters,
.submit-your-cv {
	input,
	textarea,
	select,
	.responsiv-uploader-fileupload {
		border: 0;
	    outline: 0;
	    box-shadow: none;
	    border-bottom: 2px solid #dcdcdc;
	    border-radius: 0;
	    font-size: 18px;
	    font-weight: bold;
	    height: auto;
	    padding-bottom: 10px;

	    &:focus {
			outline: 0;
			box-shadow: none;
	    }
	}

	button {
		background: transparent;
		color: #381d54;
	}

	.text-muted,
	select {
		font-size: 18px;
		color: #a59999;
		font-weight: bold;
	}

	h2 {
		margin-top: 0;
		font-size: 35px;
		color: #381d54;
		margin-bottom: 30px;
	}

	P {
		font-size: 18px;
		color: #747577;
	}

}

.submit-your-cv {

	input,
	select,
	textarea,
	.responsiv-uploader-fileupload {
		margin-bottom: 40px;
	}

	.upload-button {
		background-color: #381d54;
		color: #ffffff;
		font-size: 14px;
		border: 0;
		padding: 0 15px;
	}

	.responsiv-uploader-fileupload {
		margin-bottom: 10px;
	}

	.file-upload-text {
		margin-bottom: 40px;
	}

	.form-button {
		margin-top: 40px;
		border: 0;
		font-size: 24px;
		background: transparent;
		color: #381d54;
	}

}

.vacancies-filters,
.testimonials-filters {
	form {
		width: 100%;
	}
	.form-group {
		width: 100%;
		padding: 0 10px;

		@include respond-over($screen-sm) {
			width: 33%;
			padding-left: 20px;

			&:first-child {
				padding-left: 0px;
			}
		}
	}
	select {
		width: 100%!important;
		font-size: 24px;
		font-weight: 400;
	}
}

.drill-form {
	padding: 50px 0;
}


.testimonials-filters {
    @include respond-over($screen-sm) {
     .form-inline {
        display: flex;
            .form-group {
                padding-left: 20px;
                flex: 1;
                &:first-child {
                    padding-left: 0px;
                }
            }
        }
    }
}
