.upgrade-warning {
    background:#fffabe;
    text-align:center;
    padding:6px 0;
    zoom:1;
    filter:progid:DXImageTransform.Microsoft.Shadow(color='#b0b0b0', Direction=180, Strength=3);
    margin-bottom:12px;

    &-inner {
        background:#fff;
        padding:12px;
        margin-bottom:12px;
    }

    &-browser {
        background:no-repeat center top;
        padding-top:80px;
        height:20px;
        display: inline-block;

        &.mod-ie { background-image:url('../graphics/browser-upgrade/browser-ie.jpg'); }
        &.mod-ff { background-image:url('../graphics/browser-upgrade/browser-ff.jpg'); }
        &.mod-gc { background-image:url('../graphics/browser-upgrade/browser-gc.jpg'); }
        &.mod-as { background-image:url('../graphics/browser-upgrade/browser-as.jpg'); }
    }

    &-title {
        color:#d60b40;
        font-size:22px;
        font-weight:bold;
        margin-bottom:2px;
    }

    &-intro {
        font-size:14px;
        margin-bottom:12px;
    }

    p {
        color:#000005;
        font-size:11px;
        width:900px;
        margin:0 auto;
    }
}