.cta-block {
	position: relative;
	height: 300px;
	padding: 30px 10px;

	@include respond-over($screen-sm) {
		padding: 30px 19px;
	}

	@include respond-over($screen-md) {
		padding: 30px;
	}

	a {
		color: #ffffff;

		&:hover {
			color: #ffffff;
			text-decoration: none;
		}
	}

	p {
		color: #ffffff;
	}
}

.cta-row {
	@include make-row(3px);

	&-item {
		@include make-md-column(12, 15px);

		&.mod-jobs,
		&.mod-blog {
			@include make-lg-column(6, 3px);
		}

		&.mod-mailing {
			@include make-lg-column(2, 3px);
		}
	}

}

.mail-signup {
	background: #90278c;
	height: 200px;

	@include respond-over($screen-xs) {
		height: 300px;
	}

	&-title {
		font-size: 22px;
		margin-top: 0;
		color: #ffffff;
		padding-right: 20px;

		@include respond-over($screen-xl) {
			padding-right: 40px;
			font-size: 24px;
		}

	}

	&-container {
		position: absolute;
		bottom: 25px;
		left: 10px;
		right: 10px;

		@include respond-over($screen-sm) {
			left: 19px;
			right: 19px;
		}

		@include respond-over($screen-md) {
			left: 25px;
			right: 25px;
		}

	}

	&-bottom {
		position: relative;
		border-bottom: 1px solid #ffffff;
		padding-bottom: 10px;
		width: 100%;
	}

	&-input {
		background: transparent;
		border: 0;
		float: left;
		font-size: 15px;
		color: #fff;
		width: 80%;

		@include placeholder(#ffffff);

		@include respond-over($screen-xl) {
			font-size: 20px;
		}

		&:focus {
			outline: none;
		}
	}

	&-button {
		background: none;
		border: none;
		color: #fff;
		font-size: 20px;
		float: right;
		padding: 0;
	}

	label{
		color: #fff;
		a{
			text-decoration: underline;
		}
	}

	.alert-success{
		color: #3c763d !important;
	}

}

.latest-blog-posts {

	background-image:url('/themes/keyappointments/assets/graphics/home/latest-blog-bg.jpg');
	background-size: cover;

    @include respond-over($screen-xl) {
        .latest-blog-posts-inner {
            max-width: 540px;
            width: 100%;
            float: left;
        }
    }

	&-heading {
		color: #ffffff;
		margin: 0;
		padding-bottom: 20px;
		font-size: 26px;

		@include respond-over($screen-xl) {
			font-size: 34px;
		}

	}

	&-link {
		position: absolute;
		bottom: 25px;
		color: #ffffff;
		font-size: 22px;
	}

	&-slide {
		color: #ffffff;
		float: left;
	}

	&-date {
		font-size: 22px;
	}

	&-title {
		margin: 0;
		font-size: 16px;
	}

	&-excerpt {
		margin: 0;
		font-size: 14px;

		@include respond-over($screen-xl) {
			font-size: 16px;
		}

	}

}

.featured-jobs {

	background: #3c1b51;
	height: auto;

    @include respond-over($screen-xl) {
        .featured-job {
            max-width: 540px;
            width: 100%;
            float: right;
        }
    }

	@include respond-over($screen-sm) {
		height: 300px;
	}

	&-heading {
		color: #ffffff;
		margin: 0;
		padding-bottom: 20px;
		font-size: 26px;

		@include respond-over($screen-xl) {
			font-size: 34px;
		}

	}

	&-list {
		padding-left: 10px;
		list-style: none;
		padding-right: 15px;

		.featured-latest-jobs-slider {
			height: 150px !important;
			overflow: hidden;
			cursor: pointer;

			.slick-slide {
				
				min-height: 55px;
			}
		}
	}

	&-item {
		padding-bottom: 5px;
		font-size: 14px;
		padding-bottom: 10px;

		@include respond-over($screen-xl) {
			font-size: 16px;
			padding-bottom: 10px;
		}

		&:before {
			content: '\f103';
			font-family: "Flaticon";
			position: relative;
			font-size: 12px;
			left: 0px;
			margin-top: 2px;
			padding-right: 10px;
		}

	}

	&-link {
		text-decoration: underline;
		padding-left: 5px;
	}

	&-view-all {
		font-size: 20px;
		padding-top: 10px;

		@include respond-over($screen-sm) {
			padding-top: 0;
			position: absolute;
			bottom: 25px;
		}

	}

}
