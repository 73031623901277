.testimonial {
	border-color: #381d54;
	position: relative;
	margin-bottom: 40px;

	@include respond-over($screen-md) {
		margin-bottom: 0;
		min-height: 350px;
	}

	&-quote {
		font-weight: 100;
		padding-right: 40px;

		p {
			margin: 0;
		}

	}

	&-quotee {
		color: #381d54;
		font-size: 15px;
		margin-top: 40px;

		@include respond-over($screen-md) {
			position: absolute;
			bottom: 0;
			margin-top: 0;
		}

		p {
			margin: 0;

			&:first-child {
				font-weight: bold;
			}

		}

	}

}

.testimonials {
	.col-xs-12 {
		&:last-child {
			.testimonial {
				margin-bottom: 0;
			}
		}
	}
}