@mixin font-size($size) {
    font-size: $size;
    font-size: calculate-rem($size);
}

@mixin circle($size) {
    @include square($size);
    line-height: $size;
    text-align: center;
    display: inline-block;
    border-radius: 100%;
}

@mixin respond-over($size) {
    @media only screen and (min-width: $size) { @content; }
}

@mixin respond-under($size) {
    @media only screen and (max-width: $size - 1) { @content; }
}

@mixin respond-between($min, $max) {
    @media only screen and (min-width: $min) and (max-width: $max - 1) { @content; }
}
