body {
	font-family: 'Lato';
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {

}

h1 {

}

h2 {
    font-size: 35px;
    color: #381d54;
    margin-top: 0;
    margin-bottom: 40px;
}

h3 {

}

p {
	color: #747577;
    font-size: 18px;
}

a {

}

.text-large {
	font-size: 24px;

    @include respond-over($screen-xs) {
        font-size: 30px;
    }

}

.text-medium {
    font-size: 24px;
    color: #381d54;
}

.extra-margin {
	margin: 30px 0;
}

.no-margin {
    margin: 0;
    padding: 0;
}

.purple-heading {
	font-size: 20px;
    color: #381d54;
    margin-top: 40px;
    margin-bottom: 10px;

    @include respond-over($screen-xs) {
    	font-size: 35px;
    }

}

.purple-text {
    color: #381d54;
}

.larger-intro-text {
	font-size: 20px;
	color: #747577;
	margin-bottom: 20px;
	font-weight: 100;

	@include respond-over($screen-xs) {
		font-size: 35px;
	}

}

.button {
	background: #80187e;
    display: inline-block;
    font-size: 15px;
    padding: 15px 30px;
    margin: 15px 0 25px 0;
    text-align: center;
    color: #ffffff;

    &:after {
    	font-family: Flaticon;
    	content: "\f106";
    	margin-left: 10px
    }

    a {
        color: #ffffff;
    }

}
